import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoaderService } from '../loader/loader.service';
import { finalize } from 'rxjs';

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  let finished = false;
  const loaderSvc = inject(LoaderService);

  setTimeout(() => {
    if (!finished) {
      loaderSvc.isLoading.next(true);
    }
  }, 500);

  return next(req).pipe(
    finalize(() => {
      finished = true;
      loaderSvc.isLoading.next(false);
    })
  );
};
