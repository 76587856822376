<div class="contact_main text-white">
  <h2 class="text-center text-3xl font-bold my-2 pt-8 m-auto">
    Recupero password
  </h2>
  <form [formGroup]="form" class="w-full">
    <mat-stepper
      class="max-w-2xl w-full"
      #stepper
      orientation="horizontal"
      [linear]="true"
    >
      <mat-step [editable]="false">
        <form [formGroup]="form">
          <ng-template matStepLabel></ng-template>
          <h2 class="text-xl text-center my-4 mx-1">Inserisci la email</h2>
          <div class="form_container">
            <div class="inputs">
              <mat-form-field appearance="fill" class="w-full m-auto h-fit p-2">
                <mat-label>Email</mat-label>
                <input
                  formControlName="email"
                  matInput
                  type="email"
                  placeholder="Email"
                />
              </mat-form-field>
            </div>

            <div class="buttons">
              <button
                mat-raised-button
                color="primary"
                [disabled]="!this.form.controls['email'].valid"
                (click)="continue()"
              >
                Continua
              </button>
              <button mat-raised-button color="warn" (click)="closepopup()">
                Chiudi
              </button>
            </div>
          </div>
        </form>
      </mat-step>

      <mat-step [editable]="true">
        <form [formGroup]="form">
          <ng-template matStepLabel></ng-template>
          <h2 class="text-xl text-center my-4 mx-1">
            inserisci il codice inviato per email
          </h2>
          <div class="form_container">
            <div class="inputs">
              <mat-form-field appearance="fill" class="w-full m-auto h-fit p-2">
                <mat-label>Codice</mat-label>
                <input
                  formControlName="code"
                  matInput
                  type="text"
                  placeholder="000000"
                />
              </mat-form-field>
            </div>

            <div class="buttons">
              <button
                mat-raised-button
                color="primary"
                [disabled]="!form.controls['code'].valid"
                (click)="stepper.next()"
              >
                Continua
              </button>
              <button mat-raised-button color="warn" (click)="closepopup()">
                Chiudi
              </button>
            </div>
          </div>
        </form>
      </mat-step>

      <mat-step [editable]="true">
        <form [formGroup]="form">
          <ng-template matStepLabel></ng-template>
          <h2 class="text-xl text-center my-4 mx-1">Crea una nuova Password</h2>
          <div class="form_container">
            <div class="inputs flex flex-col gap-2 mb-8">
              <mat-form-field appearance="fill" class="w-full m-auto h-fit p-2">
                <mat-label>Nuova Password</mat-label>
                <input
                  matInput
                  formControlName="newPassword"
                  type="{{ passwordHide ? 'password' : 'text' }}"
                  placeholder="Password"
                />
                <mat-icon matPrefix>lock</mat-icon>
                <mat-icon (click)="passwordHide = !passwordHide" matSuffix>{{
                  passwordHide ? "visibility_off" : "visibility"
                }}</mat-icon>
                <mat-hint class="text-gray-500">
                  <span class="text-gray-400 font-bold">Attenzione</span> gli
                  spazi inseriti verranno eliminati automaticamente
                </mat-hint>

                <mat-hint
                  align="start"
                  class="text-red-600"
                  *ngIf="
                    form != null &&
                    form.hasError('uppercase') &&
                    form.get('confirmPassword')?.dirty &&
                    form.get('confirmPassword')?.touched
                  "
                >
                  Devi almeno inserire una lettera maiuscola
                </mat-hint>

                <mat-hint
                  align="end"
                  class="text-red-600"
                  *ngIf="
                    form != null &&
                    form.hasError('minlength') &&
                    form.get('confirmPassword')?.dirty &&
                    form.get('confirmPassword')?.touched
                  "
                >
                  La password deve essere di almeno 8 caratteri
                </mat-hint>
              </mat-form-field>

              <mat-form-field appearance="fill" class="w-full m-auto h-fit p-2">
                <mat-label>Confirm Password</mat-label>
                <input
                  formControlName="confirmPassword"
                  matInput
                  type="{{ confirmPasswordHide ? 'password' : 'text' }}"
                  placeholder="Confirm Password"
                />
                <mat-icon matPrefix>lock</mat-icon>
                <mat-icon
                  (click)="confirmPasswordHide = !confirmPasswordHide"
                  matSuffix
                  >{{
                    confirmPasswordHide ? "visibility_off" : "visibility"
                  }}</mat-icon
                >
                <mat-hint
                  class="text-red-600"
                  *ngIf="
                    form != null &&
                    form.hasError('passwordMismatch') &&
                    form.get('confirmPassword')?.dirty &&
                    form.get('confirmPassword')?.touched
                  "
                >
                  Le password non coincidono
                </mat-hint>
              </mat-form-field>
            </div>

            <div class="buttons">
              <button
                mat-raised-button
                color="primary"
                [disabled]="form.invalid"
                (click)="sendFinal()"
              >
                Continua
              </button>
              <button
                mat-raised-button
                color="orange"
                (click)="stepper.previous()"
              >
                Indietro
              </button>
              <button mat-raised-button color="warn" (click)="closepopup()">
                Chiudi
              </button>
            </div>
          </div>
        </form>
      </mat-step>
    </mat-stepper>
  </form>
</div>
