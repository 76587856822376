import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet, NavigationStart } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NavbarComponent } from './components/navbar/navbar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import { SEOService } from './services/seo.service';
import { PublicModulesFunction } from './function/public-modules-function';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  standalone: true,
  providers: [MatDatepickerModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    ...PublicModulesFunction,
    RouterOutlet,
    MatButtonModule,
    NavbarComponent,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    FullCalendarModule,
  ],
})
export class AppComponent {
  title = 'SMDRIFT';
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
  };

  public MY_DATE_FORMAT = {
    parse: {
      dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
    },
    display: {
      dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
      location: 'it-IT',
    },
  };

  constructor(
    private router: Router,
    private seo: SEOService,
    private commonService: CommonService
  ) {
    this.seo.updateTitle();

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        this.seo.cleanActive();
      }
    });
  }
  // ngOnInit(): void {
  // }
}
