import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, retry } from 'rxjs';
import { Role } from '../interfaces/role';
import { RoleCreateRequest } from '../interfaces/role-create-request';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getRoles = (): Observable<Role[]> =>
    this.http.get<Role[]>(`${this.apiUrl}Roles/get`).pipe(retry({count:3, delay:2000}));

  createRole = (roleName: string): Observable<{ message: string }> =>
    this.http.post<{ message: string }>(`${this.apiUrl}Roles/create`, {
      roleName,
    }).pipe(retry({count:3, delay:2000}));

  delete = (id: string): Observable<{ message: string }> =>
    this.http.delete<{ message: string }>(
      `${this.apiUrl}Roles/delete?id=${id}`
    ).pipe(retry({count:3, delay:2000}));

  assignRole = (
    userId: string,
    roleId: string
  ): Observable<{ message: string }> =>
    this.http.post<{ message: string }>(`${this.apiUrl}Roles/assign`, {
      userId,
      roleId,
    }).pipe(retry({count:3, delay:2000}));

  assignStockRegisterRole = (
    roleName: string
  ): Observable<{ message: string }> =>
    this.http.post<{ message: string }>(`${this.apiUrl}Roles/assignBase`, {
      roleName,
    }).pipe(retry({count:3, delay:2000}));

  deAssignRole = (
    userId: string,
    roleId: string
  ): Observable<{ message: string }> =>
    this.http.post<{ message: string }>(`${this.apiUrl}Roles/deassign`, {
      userId,
      roleId,
    }).pipe(retry({count:3, delay:2000}));
}
