import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SEOService } from '../../services/seo.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonService } from '../../services/common.service';
import { PublicModulesFunction } from '../../function/public-modules-function';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ...PublicModulesFunction,
    MatInputModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    ReactiveFormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit, OnDestroy {
  authService = inject(AuthService);
  commonService = inject(CommonService);
  seoService = inject(SEOService);
  matSnackBar = inject(MatSnackBar);
  router = inject(Router);
  hide = true;
  form!: FormGroup;

  showResendEmail: boolean = false;

  constructor(
    private ref: MatDialogRef<LoginComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public isDialog: boolean
  ) {}

  ngOnDestroy(): void {
    this.seoService.cleanActive();
  }

  login() {
    if (this.commonService.getCookie('_pScs')! >= 5) {
      return this.matSnackBar.open(
        'Hai superato i tentativi limiti, riprovare più tardi',
        'Chiudi',
        {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
    }

    this.authService.login(this.form.value).subscribe({
      next: (response) => {
        this.ref.close('Chiusura automatica');

        this.matSnackBar.open(response.message, 'Chiudi', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        this.ref.close();
      },
      error: (error) => {
        var cookieVal = this.commonService.getCookie('_pScs');

        if (error.error.token == 'noConfirm') {
          this.showResendEmail = true;
        } else {
          this.commonService.setCookie(
            '_pScs',
            cookieVal != null ? +cookieVal + 1 : 1,
            null,
            4
          );
        }

        this.form.controls['password'].setValue('');

        this.matSnackBar.open(error.error.message, 'Chiudi', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      },
    });

    return false;
  }

  resetPsw() {
    var email = this.form.controls['email'].value;

    if (email === null || email == '' || this.form.controls['email'].invalid) {
      this.form.setErrors({ resetPsw: true });
    } else {
      if (this.form.controls['email'].valid) {
        var fd = new FormData(); 

        fd.append("email", this.form.controls['email'].value);

        this.authService
          .forgotPassword(fd)
          .subscribe({
            next: (result: any) => {
              this.matSnackBar.open(result.message, 'Chiudi', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            },
            error: (error: any) => {
              this.matSnackBar.open(error.error.message, 'Chiudi', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            },
          });
      }
    }
  }

  ResendEmail() {
    var email = this.form.controls['email'].value;

    if (email === null || email == '') {
      this.form.setErrors({ resetPsw: true });
    } else {
      if (this.form.controls['email'].valid) {
        this.authService.resendConfirmEmail(email).subscribe((res) => {
          this.matSnackBar.open(res.message, 'Chiudi', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.showResendEmail = false;
        });
      }
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      rememberMe: [false],
    });
  }

  closepopup() {
    this.ref.close('Chiusura automatica');
  }
}
