<!-- <div class="flex items-center justify-center">
  <form class="shadow-md rounded-md p-5 bg-white w-1/5" [formGroup]="registerForm" (ngSubmit)="register()">

    @for(error of errors; track error){
    <div class="bg-red-100 border mb-2 flex flex-col gap-2 border-red-400 text-red-400 px4 py4 rounded-md relative">
      <div class="text-xs p-2">{{error.description}}</div>
    </div>
    }
  </form>
</div> -->

<div class="main m-4 flex-row items-center justify-center">
  <img class="back-img" src="assets/images/ForPages/Index-car.webp" alt="" />

  <mat-icon
    class="closeIcon"
    *ngIf="isDialog"
    fontIcon="close"
    (click)="closepopup()"
  ></mat-icon>
  <h1>Registrati</h1>

  <mat-stepper class="register-form no-gutter" [linear]="true" #stepper>
    <mat-step
      [stepControl]="firstForm"
      errorMessage="Riempire tutti i campi!"
      label="Accs"
      color="warn"
    >
      <form [formGroup]="firstForm">
        <mat-form-field appearance="fill" class="w-full p-2">
          <mat-label> Email</mat-label>
          <input
            matInput
            placeholder="Email"
            formControlName="email"
            appTrimInput
          />
          <mat-icon matPrefix>email</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-1/2 p-2 max-[600px]:w-full">
          <mat-label> Password</mat-label>
          <input
            matInput
            formControlName="password"
            type="{{ passwordHide ? 'password' : 'text' }}"
            placeholder="Password"
          />
          <mat-icon matPrefix>lock</mat-icon>
          <mat-icon (click)="passwordHide = !passwordHide" matSuffix>{{
            passwordHide ? "visibility_off" : "visibility"
          }}</mat-icon>
          <mat-hint class="text-gray-500">
            <span class="text-gray-400 font-bold">Attenzione</span> gli spazi
            inseriti verranno eliminati automaticamente
          </mat-hint>

          <mat-hint
            align="start"
            class="text-red-600"
            *ngIf="
              firstForm.hasError('uppercase') &&
              firstForm.get('confirmPassword')?.dirty &&
              firstForm.get('confirmPassword')?.touched
            "
          >
            Devi almeno inserire una lettera maiuscola
          </mat-hint>

          <mat-hint
            align="end"
            class="text-red-600"
            *ngIf="
              firstForm.hasError('minlength') &&
              firstForm.get('confirmPassword')?.dirty &&
              firstForm.get('confirmPassword')?.touched
            "
          >
            La password deve essere di almeno 8 caratteri
          </mat-hint>
        </mat-form-field>

        <mat-form-field
          appearance="fill"
          class="w-1/2 h-fit p-2 max-[600px]:w-full"
        >
          <mat-label>Confirm Password</mat-label>
          <input
            formControlName="confirmPassword"
            matInput
            type="{{ confirmPasswordHide ? 'password' : 'text' }}"
            placeholder="Confirm Password"
          />
          <mat-icon matPrefix>lock</mat-icon>
          <mat-icon
            (click)="confirmPasswordHide = !confirmPasswordHide"
            matSuffix
            >{{
              confirmPasswordHide ? "visibility_off" : "visibility"
            }}</mat-icon
          >
          <mat-hint
            class="text-red-600"
            *ngIf="
              firstForm.hasError('passwordMismatch') &&
              firstForm.get('confirmPassword')?.dirty &&
              firstForm.get('confirmPassword')?.touched
            "
          >
            Le password non coincidono
          </mat-hint>
          <mat-hint
            class="text-red-600"
            *ngIf="
              firstForm.hasError('alreadyLogged') &&
              firstForm.get('confirmPassword')?.dirty &&
              firstForm.get('confirmPassword')?.touched
            "
          >
            Se non puoi accedere alla mail contattaci a:
            <span [innerHTML]="email"></span> o usando la sezione contattaci
            nella home
          </mat-hint>
        </mat-form-field>

        <mat-checkbox
          formControlName="acceptUserAgreement"
          color="warn"
          class="p-4 pb-0"
          ><p class="text-white userAgreement">
            Dichiaro di aver letto
            <span
              class="underline"
              (click)="openPrivacy(); $event.stopPropagation()"
              >l'Informativa privacy</span
            >
            e acconsento al trattamento dei miei dati personali.
          </p></mat-checkbox
        >
        <mat-checkbox
          formControlName="acceptNewsletter"
          color="warn"
          class="p-4 pt-0"
          ><p class="text-white userAgreement">
            Desidero iscrivermi alla Newsletter
          </p></mat-checkbox
        >

        <div class="text-end pt-4">
          <button
            mat-raised-button
            color="orange"
            [disabled]="!firstForm.valid"
            (click)="goForward(stepper)"
            class="mx-2"
          >
            Continua
          </button>
        </div>
      </form>
    </mat-step>

    <mat-step
      [stepControl]="thirdForm"
      errorMessage="Inserire correttamente i dati"
      label="Dati"
      state="chat"
      [ngStyle]="{ color: 'bg-white' }"
      color="warn"
    >
      <form [formGroup]="thirdForm">
        <div
          appearance="fill"
          class="w-1/2 p-2 max-[600px]:w-full max-[600px]:text-center"
        ></div>

        <mat-form-field appearance="fill" class="w-1/2 p-2 max-[600px]:w-full">
          <mat-label>Nome</mat-label>
          <input
            matInput
            placeholder="Nome"
            formControlName="Nome"
            appTrimInput
          />
          <mat-icon matPrefix>person</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-1/3 p-2 max-[600px]:w-full">
          <mat-label>Data di nascita</mat-label>
          <input
            matInput
            [matDatepicker]="datepicker"
            [formControl]="age"
            placeholder="Immetti la data"
          />
          <mat-datepicker-toggle
            matPrefix
            style="color: black"
            [for]="datepicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #datepicker>
            <mat-datepicker-actions>
              <button mat-button matDatepickerCancel>Cancel</button>
              <button mat-raised-button matDatepickerApply>Apply</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-hint class="text-red-600" *ngIf="thirdForm.hasError('ageError')">
            La data di nascita non è valida
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-1/2 p-2 max-[600px]:w-full">
          <mat-label>Cognome</mat-label>
          <input
            matInput
            placeholder="Cognome"
            formControlName="Cognome"
            appTrimInput
          />
          <mat-icon matPrefix>person</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-1/2 p-2 max-[600px]:w-full">
          <mat-label>Numero di telefono</mat-label>
          <input
            matInput
            placeholder="+39"
            formControlName="PhoneNumber"
            appTrimInput
          />
          <mat-icon matPrefix>phone</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-1/2 p-2 max-[600px]:w-full">
          <mat-label>Codice Fiscale / Codice ISS</mat-label>
          <input
            matInput
            placeholder="Codice Fiscale"
            formControlName="CodFiscale"
            appTrimInput
            appUppercase
          />
          <mat-icon matPrefix>assignment_ind</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-1/2 p-2 max-[600px]:w-full">
          <mat-label>Nickname social (opzionale)</mat-label>
          <input
            matInput
            placeholder="Social"
            formControlName="Social"
            appTrimInput
          />
        </mat-form-field>

        <div class="text-end pt-4">
          <button
            mat-raised-button
            color="warn"
            matStepperPrevious
            class="mx-2"
          >
            Indietro
          </button>

          <button
            mat-raised-button
            color="orange"
            (click)="register(stepper)"
            [disabled]="!thirdForm.valid"
            class="mx-2"
          >
            Register
          </button>
        </div>
      </form>
    </mat-step>

    <ng-template matStepperIcon="stack">
      <mat-icon>stack</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="chat">
      <mat-icon>forum</mat-icon>
    </ng-template>
  </mat-stepper>

  <p (click)="openLogin()" [routerLink]="['/']" class="toLogin relative z-10">
    Sei già registrato ? <a class="underline">Clicca qui per il login</a>
  </p>
</div>
