import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { PublicModulesFunction } from '../../function/public-modules-function';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-psw',
  standalone: true,
  imports: [
    ...PublicModulesFunction,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './reset-psw.component.html',
  styleUrl: './reset-psw.component.scss',
})
export class ResetPswComponent {
  @ViewChild('stepper') stepper!: MatStepper;

  form!: FormGroup;
  select!: '';

  confirmPasswordHide: boolean = true;
  passwordHide: boolean = true;
  oldPasswordHide: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private ref: MatDialogRef<ResetPswComponent>,
    private matSnackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        code: ['', [Validators.required]],
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validators: [
          this.passwordMatchValidator,
          this.passwordUpperCase,
          this.passwordminlenght,
        ],
      }
    );

    if (this.data.id != null) {
      this.form.controls['email'].setValue(this.data.id);

      if (this.form.controls['email'].valid) {
        this.continue();
      }
    }
  }

  continue() {
    if (this.form.controls['email'].valid) {
      var formData = new FormData();
      formData.append('email', this.form.controls['email'].value);

      this.authService.forgotPassword(formData).subscribe((res) => {
        this.matSnackBar.open(res.message, 'Chiudi', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        if (res.isSuccess) {
          this.stepper.next();
        }
      });
    }
  }

  sendFinal() {
    if (this.form.valid) {
      var formdata = new FormData();
      formdata.append('email', this.form.controls['email'].value);
      formdata.append('token', this.form.controls['code'].value);
      formdata.append('psw', this.form.controls['newPassword'].value);

      this.authService.resetPassword(formdata).subscribe((res) => {
        this.matSnackBar.open(res.message, 'Chiudi', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        if (res.isSuccess) {
          this.authService.logout();
          this.matSnackBar.open('Accedi nuovamente', 'OK', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.closepopup();
          this.router.navigate(['/']);
        }
      });
    }
  }

  closepopup() {
    this.ref.close('Chiusura automatica');
  }

  private passwordMatchValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get('newPassword')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;

    if (password !== confirmPassword) {
      return { passwordMismatch: true };
    }

    return null;
  }

  private passwordUpperCase(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get('newPassword')?.value;

    if (!/[A-Z]/.test(password)) {
      return { uppercase: true };
    }

    return null;
  }

  private passwordminlenght(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get('newPassword')?.value;

    if (password.length < 8) {
      return { minlength: true };
    }

    return null;
  }
}
