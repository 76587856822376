import { Routes } from '@angular/router';
import { authGuard, authGuardSelf } from './guards/auth.guard';
import { roleGuard } from './guards/role.guard';
import { NotFoundComponent } from './components/404/404.component';
import { title } from 'process';
import { shopGuard } from './guards/shop.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
    data: {
      title: '',
      author: 'Il nostro favoloso Team SMDrift',
      description:
        'Team Drift, corsi, eventi e tanto altro! Entra a far parte del team sempre in espansione e con professionisti',
    },
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./pages/privacy-policy/privacy-policy.component').then(
        (m) => m.PrivacyPolicyComponent
      ),
    data: {
      title: 'Privacy',
      description:
        "Le nostre politiche di privacy e policy per l'utilizzo corretto e il funzionamento del sito e del team",
    },
  },
  {
    path: 'chisiamo',
    loadComponent: () =>
      import('./pages/chisiamo/chisiamo.component').then(
        (m) => m.ChisiamoComponent
      ),
    data: {
      title: 'Chi siamo',
      description:
        'Entra e vieni a scoprire chi siamo, dove siamo e come partecipare ai nostri eventi',
    },
  },
  {
    path: 'detail-eventi',
    loadComponent: () =>
      import('./pages/eventi-detail/eventi-detail.component').then(
        (m) => m.AbbonamentiComponent
      ),
    data: {
      title: 'Dettagli',
      description:
        'Sezione dettagli eventi, Tutto ciò che devi sapere per poter partecipare',
    },
  },
  {
    path: 'gallery',
    loadComponent: () =>
      import('./pages/gallery/gallery.component').then(
        (m) => m.GalleryComponent
      ),
    data: {
      title: 'Galleria',
      description:
        'La nostra galleria delle foto negli eventi e nelle manifestazioni!',
    },
  },
  {
    path: 'newsletter',
    loadComponent: () =>
      import('./pages/newsletter/newsletter.component').then(
        (m) => m.NewsletterComponent
      ),
    data: {
      title: 'NewsLetter',
      description:
        'La nostra newsletter per aggiornamenti, miglioramenti e tanto altro!',
    },
  },
  {
    path: 'newsletterSettings',
    loadComponent: () =>
      import('./pages/newsletter-settings/newsletter-settings.component').then(
        (m) => m.NewsletterSettingsComponent
      ),
    canActivate: [authGuard],
    data: {
      title: 'NewsLetter-Settings',
      roles: ['Admin'],
    },
  },
  {
    path: 'shop',
    loadComponent: () =>
      import('./pages/shop/shop.component').then((m) => m.ShopComponent),
    canActivate: [authGuard, shopGuard],
    data: {
      title: 'Shop',
      description:
        'Il nostro personale shop per poter comprare gadget, abbigliamento e tanto altro',
    },
  },
  {
    path: 'shop/cart',
    loadComponent: () =>
      import('./pages/cart-detail/detail.component').then(
        (m) => m.OrderDetailComponent
      ),
    canActivate: [authGuard, shopGuard],
    data: {
      title: 'Carrello',
    },
  },
  {
    path: 'shop/product/admin',
    loadComponent: () =>
      import('./pages/shop-settings/shop-settings.component').then(
        (m) => m.ShopSettingsComponent
      ),
    canActivate: [roleGuard],
    data: {
      title: 'Shop-Settings',
      roles: ['Admin'],
    },
  },
  {
    path: 'shop/order/admin',
    loadComponent: () =>
      import('./pages/order-settings/order-settings.component').then(
        (m) => m.OrderSettingsComponent
      ),
    canActivate: [roleGuard],
    data: {
      title: 'Order-Settings',
      roles: ['Admin'],
    },
  },
  {
    path: 'shop/order',
    loadComponent: () =>
      import('./pages/order/order.component').then((m) => m.OrderComponent),
    canActivate: [authGuard, shopGuard],
    data: {
      title: 'Ordine',
    },
  },
  {
    path: 'ConfirmChangeEmail',
    loadComponent: () =>
      import('./pages/change-email/change-email.component').then(
        (m) => m.ChangeEmailComponent
      ),
    data: {
      title: 'Conferma mail',
    },
  },
  {
    path: 'carImageSettings',
    loadComponent: () =>
      import('./pages/car-image-settings/car-image-settings.component').then(
        (m) => m.CarImageSettingsComponent
      ),
    canActivate: [roleGuard],
    data: {
      title: 'carImage-Settings',
      roles: ['Admin'],
    },
  },
  {
    path: 'private-Gallery',
    loadComponent: () =>
      import('./pages/account-gallery/account-gallery.component').then(
        (m) => m.AccountGalleryComponent
      ),
    canActivate: [roleGuard, authGuardSelf],
    data: {
      title: 'Galleria privata',
      roles: ['Pilota', 'Admin'],
    },
  },
  {
    path: 'AccountConfirm',
    loadComponent: () =>
      import('./pages/account-confirm/account-confirm.component').then(
        (m) => m.AccountConfirmComponent
      ),
    data: {
      title: 'Conferma Account',
    },
  },
  {
    path: 'gallerysettings',
    loadComponent: () =>
      import('./pages/gallery-settings/gallery-settings.component').then(
        (m) => m.GallerySettingsComponent
      ),
    canActivate: [authGuard],
    data: {
      title: 'Galleria admin',
      roles: ['Admin'],
    },
  },
  {
    path: 'eventisettings',
    loadComponent: () =>
      import('./pages/eventi-settings/eventi-settings.component').then(
        (m) => m.EventiSettingsComponent
      ),
    canActivate: [authGuard],
    data: {
      title: 'Eventi admin',
      roles: ['Admin'],
    },
  },
  {
    path: 'eventi',
    loadComponent: () =>
      import('./pages/eventi/eventi.component').then((m) => m.EventiComponent),
    canActivate: [authGuard],
    data: {
      title: 'Eventi',
    },
  },
  {
    path: 'buntas',
    loadComponent: () =>
      import('./pages/buntas/buntas.component').then((m) => m.BuntasComponent),
    data: {
      title: "Bunta's",
      description:
        "L'evento statico di auto giapponesi più bello in San Marino",
    },
  },
  {
    path: 'account/:id',
    loadComponent: () =>
      import('./pages/account/account.component').then(
        (m) => m.AccountComponent
      ),
    canActivate: [authGuard, authGuardSelf],
    data: {
      title: 'Profilo',
    },
  },
  {
    path: 'users',
    loadComponent: () =>
      import('./pages/users/users.component').then((m) => m.UsersComponent),
    canActivate: [roleGuard],
    data: {
      title: 'Profili admin',
      roles: ['Admin'],
    },
  },
  {
    path: 'contactlist',
    loadComponent: () =>
      import('./pages/list-contacts/list-contacts.component').then(
        (m) => m.ListContactsComponent
      ),
    canActivate: [roleGuard],
    data: {
      title: 'Contatti admin',
      roles: ['Admin'],
    },
  },
  {
    path: 'abboAdmin',
    loadComponent: () =>
      import('./pages/abbonamenti-admin/abbonamenti-admin.component').then(
        (m) => m.AbbonamentiAdminComponent
      ),
    canActivate: [roleGuard],
    data: {
      title: 'Abbonamenti admin',
      roles: ['Admin'],
    },
  },
  {
    path: 'roles',
    loadComponent: () =>
      import('./pages/role/role.component').then((m) => m.RoleComponent),
    canActivate: [roleGuard],
    data: {
      title: 'Ruoli admin',
      roles: ['Admin'],
    },
  },
  {
    path: 'api/**',
    pathMatch: 'full',
    redirectTo: '',
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      title: 'Non trovato',
    },
  },
];
