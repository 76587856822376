import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const matSnackbar = inject(MatSnackBar);

  if (inject(AuthService).isLoggedIn()) {
    return true;
  }

  matSnackbar.open(
    "Devi aver effettuato l'accesso per accedere a questa pagina",
    'Ok',
    {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    }
  );
  // inject(Router).navigate(['/']);
  return false;
};

export const authGuardSelf: CanActivateFn = (route, state) => {
  const matSnackbar = inject(MatSnackBar);

  var user = inject(AuthService).getUserDetail();

  if (user?.roles == 'Admin' || user?.roles.includes('Admin')) {
    return true;
  }

  if (route.url[1].path == user?.id) {
    return true;
  }

  matSnackbar.open(
    "Non puoi accedere a pagine non di competenza!",
    'Ok',
    {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    }
  );
  inject(Router).navigate(['/']);
  
  return false;
};
