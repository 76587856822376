import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, retry } from 'rxjs';
import { Product } from '../interfaces/Product';
import { AuthResponse } from '../interfaces/auth-response';
import { ItemBought } from '../interfaces/order';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getAllProduct = (all: boolean = false): Observable<any> =>
    this.http
      .get<any>(`${this.apiUrl}Shop/getall-product`, { params: { all: all } })
      .pipe(retry({ count: 3, delay: 2000 }));

  getAllCategories = (): Observable<any> =>
    this.http
      .get<any>(`${this.apiUrl}Shop/getAllProductCategories`)
      .pipe(retry({ count: 3, delay: 2000 }));

  getCategoryById = (id: string): Observable<any> =>
    this.http
      .get<any>(`${this.apiUrl}Shop/getCategory-byId?id=${id}`)
      .pipe(retry({ count: 3, delay: 2000 }));

  getProductById = (id: number): Observable<any> =>
    this.http
      .get<any>(`${this.apiUrl}Shop/getProduct-byId?id=${id}`)
      .pipe(retry({ count: 3, delay: 2000 }));

  getAllOrder = (id: any): Observable<Product[]> =>
    this.http
      .get<any>(`${this.apiUrl}Shop/getall-order?id=${id}`)
      .pipe(retry({ count: 3, delay: 2000 }));

  getOrderStatus = (id: number, userId: string): Observable<any> =>
    this.http.get<any>(
      `${this.apiUrl}Shop/getOrderStatus?id=${id}&UserId=${userId}`
    );

  getOrderById = (id: string, userId: string): Observable<Product[]> =>
    this.http
      .get<Product[]>(
        `${this.apiUrl}Shop/getOrder-byId?id=${id}&UserId=${userId}`
      )
      .pipe(retry({ count: 3, delay: 2000 }));

  getPaypalOrderById = (id: string, userId: string): Observable<any> =>
    this.http
      .get<any>(
        `${this.apiUrl}Shop/getPaypalOrder-byId?id=${id}&UserId=${userId}`
      )
      .pipe(retry({ count: 3, delay: 2000 }));

  getPaypalCategory = (): Observable<any> =>
    this.http
      .get<any>(`${this.apiUrl}Shop/GetPaypalCategories`)
      .pipe(retry({ count: 3, delay: 2000 }));

  CreateCart = (userId: string): Observable<AuthResponse> =>
    this.http
      .post<AuthResponse>(`${this.apiUrl}Shop/CreateCart`, {
        email: userId,
      })
      .pipe(retry({ count: 3, delay: 2000 }));

  UpdateOrderShip = (data: FormData): Observable<AuthResponse> =>
    this.http
      .post<AuthResponse>(`${this.apiUrl}shop/updateOrderShip`, data)
      .pipe(retry({ count: 5, delay: 3000 }));

  PickCart = (userId: string, cartId: string | null): Observable<any> => {
    let params = new HttpParams().set('email', userId);
    if (cartId != null) params = params.set('cartId', cartId);

    return this.http.get<any>(`${this.apiUrl}Shop/PickCart`, { params });
  };

  setPayPalBuy = (userId: string, cartId: string): Observable<any> => {
    let params = new HttpParams().set('id', userId);
    params = params.set('cartId', cartId);

    return this.http
      .get<any>(`${this.apiUrl}Shop/CartPaypal`, { params })
      .pipe(retry({ count: 3, delay: 2000 }));
  };

  statusCartPaypal = (data: any): Observable<AuthResponse> =>
    this.http.post<AuthResponse>(`${this.apiUrl}Shop/CartBought`, data);

  NrObjInCart = (userId: string, OrderId: string): Observable<number> =>
    this.http
      .get<number>(
        `${this.apiUrl}Shop/ObjInCart?UserId=${userId}&OrderId=${OrderId}`
      )
      .pipe(retry({ count: 3, delay: 2000 }));

  MoveItemInCart = (data: FormData): Observable<AuthResponse> =>
    this.http.post<AuthResponse>(`${this.apiUrl}Shop/MoveItemInCart`, data);

  RemoveItemInCart = (data: ItemBought): Observable<AuthResponse> =>
    this.http.post<AuthResponse>(`${this.apiUrl}Shop/RemoveItemInCart`, data);

  ConfirmCart = (data: FormData): Observable<any> =>
    this.http.post<any>(`${this.apiUrl}Shop/confirmCart`, data);

  ChangeStatus = (data: FormData): Observable<AuthResponse> =>
    this.http.post<AuthResponse>(`${this.apiUrl}Shop/ChangeStatusCart`, data);

  CreateProduct = (data: FormData): Observable<AuthResponse> =>
    this.http.post<AuthResponse>(`${this.apiUrl}Shop/createProduct`, data);

  CreateCategory = (data: FormData): Observable<AuthResponse> =>
    this.http.post<AuthResponse>(`${this.apiUrl}Shop/createCategory`, data);

  ModifyCategory = (data: FormData): Observable<AuthResponse> =>
    this.http.post<AuthResponse>(`${this.apiUrl}Shop/modifyCategory`, data);

  ModifyProduct = (data: FormData): Observable<AuthResponse> =>
    this.http.post<AuthResponse>(`${this.apiUrl}Shop/modifyProduct`, data);

  deleteCategory = (data: FormData): Observable<AuthResponse> =>
    this.http.post<AuthResponse>(`${this.apiUrl}Shop/deleteCategory`, data);

  deleteProduct = (data: FormData): Observable<AuthResponse> =>
    this.http.post<AuthResponse>(`${this.apiUrl}Shop/deleteProduct`, data);
}
